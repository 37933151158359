module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"static/icon.png","name":"Saranga Publishing","description":"Igniting Indian Intellectual Vitality","short_name":"Saranga","start_url":"/","background_color":"#fffefa","theme_color":"#dba451","display":"standalone","icons":[{"src":"./src/icons/maskable_icon_x384.png","sizes":"384x384","type":"image/png","purpose":"any maskable"},{"src":"./src/icons/maskable_icon_x128.png","sizes":"128x128","type":"image/png","purpose":"any maskable"},{"src":"./src/icons/maskable_icon_x192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"./src/icons/maskable_icon_x512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"},{"src":"./src/icons/maskable_icon_x96.png","sizes":"96x96","type":"image/png","purpose":"any maskable"},{"src":"./src/icons/maskable_icon_x72.png","sizes":"72x72","type":"image/png","purpose":"any maskable"},{"src":"./src/icons/maskable_icon_x48.png","sizes":"48x48","type":"image/png","purpose":"any maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4d19f373d7406e6a624076ba8691db3e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Poppins","Source Serif Pro"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"262670991","head":false,"anonymize":true,"respectDNT":true,"exclude":["/preview/**","/do-not-track/me/too/"],"pageTransitionDelay":0,"defer":false,"sampleRate":5,"siteSpeedSampleRate":10,"cookieDomain":"sarangapublishing.com"},
    }]
